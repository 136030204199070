const initBlock = async function( block ) {
	const question = block.find( "[data-question]" );
	const questionBody = question.find( "[data-question-body]" );

	block.find( ".section-contact-us-faq__item:first-of-type" ).addClass( "active" );

	questionBody.not( ":first" ).hide();

	question.find( ".section-contact-us-faq__item-header" ).on( "click", function() {
		const index = $( this ).parent().index();
		const currentQuestion = question.eq( index );
		const currentQuestionBody = questionBody.eq( index );

		if ( currentQuestion.hasClass( "active" ) ) {
			currentQuestion.removeClass( "active" );
			currentQuestionBody.slideUp( 300 );
		} else {
			question.not( currentQuestion ).removeClass( "active" );
			questionBody.not( currentQuestionBody ).slideUp( 300 );
			currentQuestion.addClass( "active" );
			currentQuestionBody.slideDown( 300 );
		}
	} );
};

themeUtils.loadBlock( initBlock, "contact-us-faq", ".section-contact-us-faq" );
